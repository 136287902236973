import html2Canvas from "html2canvas";
import JsPDF from "jspdf";

// function dataURLtoBlob(dataurl) {
//   var arr = dataurl.split(","),
//     mime = arr[0].match(/:(.*?);/)[1],
//     bstr = atob(arr[1]),
//     n = bstr.length,
//     u8arr = new Uint8Array(n);
//   while (n--) {
//     u8arr[n] = bstr.charCodeAt(n);
//   }
//   return new Blob([u8arr], { type: mime });
// }

// const htmlToPdfWithoutPage = {
//   exportPDF(filename) {
//     const scale = 2;
//     html2Canvas(document.querySelector("#invoiceContent"), {
//       allowTaint: true, // Whether to allow cross-origin images to taint the canvas
//       scale, // The scale to use for rendering. Defaults to the browsers device pixel ratio.
//     }).then((canvas) => {
//       const contentWidth = canvas.width / scale;
//       const contentHeight = canvas.height / scale;
//       const pdf = new JsPDF("", "pt", [contentWidth, contentHeight]);
//       const pageData = canvas.toDataURL("image/jpeg", 1.0);
//       pdf.addImage(pageData, "JPEG", 0, 0, contentWidth, contentHeight);
//       pdf.save(`${filename}.pdf`);
//     });
//   },
// };

const htmlToPdf = {
  // exportPDFnew(title) {
  //   var element = document.getElementById("invoiceContent");
  //   html2Canvas(element, {
  //     logging: false,
  //   }).then(function(canvas) {
  //     var pdf = new JsPDF("p", "mm", "a4"); //A4纸，纵向
  //     var ctx = canvas.getContext("2d"),
  //       a4w = 190,
  //       a4h = 277, //A4大小，210mm x 297mm，四边各保留10mm的边距，显示区域190x277
  //       imgHeight = Math.floor((a4h * canvas.width) / a4w), //按A4显示比例换算一页图像的像素高度
  //       renderedHeight = 0;
  //     while (renderedHeight < canvas.height) {
  //       var page = document.createElement("canvas");
  //       page.width = canvas.width;
  //       page.height = Math.min(imgHeight, canvas.height - renderedHeight); //可能内容不足一页
  //       //用getImageData剪裁指定区域，并画到前面创建的canvas对象中
  //       page
  //         .getContext("2d")
  //         .putImageData(
  //           ctx.getImageData(
  //             0,
  //             renderedHeight,
  //             canvas.width,
  //             Math.min(imgHeight, canvas.height - renderedHeight)
  //           ),
  //           0,
  //           0
  //         );
  //       pdf.addImage(
  //         page.toDataURL("image/jpeg", 1.0),
  //         "JPEG",
  //         10,
  //         10,
  //         a4w,
  //         Math.min(a4h, (a4w * page.height) / page.width)
  //       ); //添加图像到页面，保留10mm边距
  //       renderedHeight += imgHeight;
  //       if (renderedHeight < canvas.height) pdf.addPage(); //如果后面还有内容，添加一个空页
  //       // delete page;
  //     }
  //     pdf.save(title);
  //   });
  // },

  exportPDFWithoutPage(filename, selector = "#invoiceContent") {
    const scale = 2;
    html2Canvas(document.querySelector(selector), {
      // scale: window.devicePixelRatio > 2 ? 2 : window.devicePixelRatio,
      scale: 1.2, // solve long pdf blank issue
      // The scale to use for rendering. Defaults to the browsers device pixel ratio.
      allowTaint: true, // Whether to allow cross-origin images to taint the canvas
    }).then((canvas) => {
      const contentWidth = canvas.width / scale;
      const contentHeight = canvas.height / scale;
      const pdf = new JsPDF("", "pt", [contentWidth, contentHeight]);
      const pageData = canvas.toDataURL("image/jpeg", 1.0);

      //Blob转化为url，必须接受Blob对象
      // let blob = dataURLtoBlob(pageData);
      // let url = window.URL.createObjectURL(blob);
      // alert(url);

      pdf.addImage(pageData, "JPEG", 0, 0, contentWidth, contentHeight);
      pdf.save(`${filename}.pdf`);
    });
  },

  exportPDF(title, selector = "#invoiceContent") {
    html2Canvas(document.querySelector(selector), {
      allowTaint: true,
      //adds below to make image clear
      scale: 2,
      //adds below to resolve screenshot of partial screen
      scrollX: 0,
      scrollY: window.scrollY,
    }).then((canvas) => {
      //内容的宽度
      let contentWidth = canvas.width;
      //内容高度
      let contentHeight = canvas.height;
      //一页pdf显示html页面生成的canvas高度,a4纸的尺寸[595.28,841.89];
      let pageHeight = (contentWidth / 592.28) * 841.89;
      //未生成pdf的html页面高度
      let leftHeight = contentHeight;
      //页面偏移
      let position = 0;
      //a4纸的尺寸[595.28,841.89]，html页面生成的canvas在pdf中图片的宽高
      let imgWidth = 595.28;
      let imgHeight = (592.28 / contentWidth) * contentHeight;
      //canvas转图片数据
      let pageData = canvas.toDataURL("image/jpeg", 1.0);
      //新建JsPDF对象
      let PDF = new JsPDF("", "pt", "a4");
      //判断是否分页
      if (leftHeight < pageHeight) {
        PDF.addImage(pageData, "JPEG", 0, 0, imgWidth, imgHeight);
      } else {
        while (leftHeight > 0) {
          PDF.addImage(pageData, "JPEG", 0, position, imgWidth, imgHeight);
          leftHeight -= pageHeight;
          position -= 841.89;
          if (leftHeight > 0) {
            PDF.addPage();
          }
        }
      }
      //保存文件
      PDF.save(title + ".pdf");
    });
  },
};

export default htmlToPdf;
